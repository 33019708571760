@import "styles/mixins/index";

.main-header {
  border-bottom: 1px solid var(--gray-border-color);
  @include breakpoint("max-md") {
    padding: map-get($pixel, md) 0;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: max(217px) minmax(0, 1fr) min(364px);
    gap: 0 map-get($pixel, md) * 3;
    align-items: map-get($layout, center);
    justify-content: space-between;
    @media (max-width: 1560px) {
      gap: 0 40px;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
      display: flex;
      align-items: map-get($layout, center);
    }
    .grid-items:first-child {
      img {
        max-width: 220px;
        max-height: 70px;
      }
      @include breakpoint("max-md") {
        div {
          @include flex-center;
        }
        div > img {
          max-width: 130px;
          @include pointer;
        }
        img {
          max-width: 130px;
        }
      }
    }
    .grid-items:last-child {
      @include flex-center;
      justify-content: flex-end;
      @include breakpoint("max-md") {
        justify-content: flex-end;
      }
    }
    .grid-items {
      .mobile-menu {
        display: none;
        @include breakpoint("max-md") {
          display: block;
        }
        i {
          font-size: 22px;
          @include pointer;
          color: var(--black-icon);
        }
      }
      .my-account-dropdown {
        position: absolute;
        right: 0;
        top: 40px;
        box-shadow: 0 0 15px #0000001a;
        width: 170px;
        z-index: 999999;
      }
      .my-account {
        @include flex-center;
        position: relative;
        @include pointer;
        @include breakpoint("max-md") {
          margin-right: 20px;
          @include breakpoint("max-sm") {
            margin-right: 12px;
            }
          .username-text {
            display: none;
          }
        }
        i {
          font-size: 25px;
          @include breakpoint("max-sm") {
            font-size: 22px;
            }
        }

        div + div > span {
          @include heading-text-sm-normal;
          color: var(--black-text-color);
          display: block;
          padding-left: 7px;
        }
      }
      .my-account-profile-dropdown {
        background-color: var(--bg-white);
        padding: map-get($pixel, md) * 2;
        border-radius: map-get($pixel, sm) - 1px;
        border: 1px solid var(--light-border-color);
        a:last-child {
          padding: 0;
        }
        > a {
          @include heading-text-sm-normal;
          color: var(--black-3);
          display: block;
          @include pointer;
          padding: 0 0 map-get($pixel, sm) * 3 0;
          @include ease-in-out;
          &:hover {
            color: var(--secondary-color);
          }
        }
      }
      .info-relative {
        position: relative;

        .shopping-cart-dropdown {
          position: absolute;
          width: 340px;
          max-height: 0px;
          @include ease-in-out;
          overflow: hidden;
          margin: map-get($pixel, md) + 4px 0 0 0;
          box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.16);
          z-index: 9999999;
          background-color: var(--bg-white);
          left: 50%;
          transform: translateX(-80%);
          @include breakpoint("max-sm") {
            left: auto;
            right: -68px;
            transform: translateX(0%);
          }
          .shopping-cart-border {
            border: 1px solid var(--bg-light-200);
          }
          .view-checkout-cart {
            // padding: 30px 21px;
            padding: map-get($pixel, sm) * 2 + 3px map-get($pixel, md) * 2 + 6px
              map-get($pixel, md) * 3 - 2px map-get($pixel, md) * 2 + 6px;

            @include flex-between;
            .yourcart-button {
              button {
                padding: 10px 24px;
                border-radius: 5px;
                @include heading-6;
                color: var(--bg-white);
                height: 40px;
                line-height: 15px;
                border: none;
                background-color: var(--secondary-color);
              }
            }
            .checkout-button {
              button {
                padding: 10px 24px;
                border-radius: 5px;
                @include heading-6;
                color: var(--bg-white);
                height: 40px;
                line-height: 15px;
                border: none;
                background-color: var(--primary-color);
              }
            }
          }

          .total-payment {
            padding: 0 map-get($pixel, md) * 2 + 1px;
            @include flex-between;
            p,
            h4 {
              margin: 0;
            }
            p {
              @include heading-4;
              color: var(--black-3);
            }
            h4 {
              @include text;
              font-size: 17px;
              color: var(--primary-color);
              @include font-semi-bold;
            }
          }
          .list-view-design-align {
            padding: 25px 21px 18px 21px;
            height: 333px;
            overflow: auto;
            overflow-x: hidden;
            .cart-empty-message {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            }
            .cart-grid:last-child {
              border-bottom: 1px solid var(--gray-border-color);
            }
            .cart-grid {
              padding: 0 0 map-get($pixel, md) * 2 + map-get($pixel, sm) 0;
              @include grid-layout;
              grid-template-columns: 80px minmax(0, 1fr) 10px;
              gap: 0 13px;
              .cart-grid-items {
                img {
                  @include pointer;
                }
                h3 {
                  @include heading-text-sm-normal;
                  margin: 0 0 map-get($pixel, md) 0;
                  color: var(--primary-color);
                  cursor: pointer;
                }
                p {
                  @include heading-text-sm-normal;
                  color: var(--black-1);
                  margin: 0;
                }
                .product-image-show {
                  width: 100%;
                  border-radius: 6px;
                  height: 80px;
                  @include flex-center;
                  justify-content: map-get($layout, center);
                  background-color: var(--bg-light-300);
                  img {
                    min-width: 52px;
                    max-height: 66px;
                    height: 66px;
                    width: 52px;
                  }
                }
              }
            }
          }
          .multiple-cart-list-view-design-align {
            margin: 4px;
            padding: 8px 8px 0px;
            height: 333px;
            overflow: auto;
            overflow-x: hidden;
            border: 4px solid var(--primary-color);
            .cart-empty-message {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            }
            .total-payment-info {
              padding: 0 map-get($pixel, md) * 2 + 1px;
              display: flex;
              justify-content: center;
              align-items: center;
              h4 {
                margin: 0;
                margin-left: 12px;
              }
              p {
                @include heading-4;
                color: var(--black-3);
              }
              h4 {
                @include text;
                font-size: 17px;
                color: var(--primary-color);
                @include font-semi-bold;
              }
            }

            .multiple-cart {
              border-bottom: 1px solid var(--primary-color);
            }
            .multiple-cart:last-child {
              border-bottom: none;
            }

            .multiple-cart-info {
              @include flex-center;
              justify-content: center;
              margin: 12px 0px;
              p {
                font-size: 16px;
                line-height: 22px;
                font-family: var(--text-font-family), var(--default-font-family);
                font-weight: 400;
                font-size: 17px;
                color: var(--primary-color);
                font-weight: 600;
              }
              .multiple-cart-info-wrapper {
                @include flex-center;
                @include pointer;
                padding-left: 20px;
                div:first-child {
                  @include flex-center;
                }
                i {
                  font-size: 24px;
                  color: var(--black-icon);
                  @include pointer;
                }
                div + div {
                  padding-left: map-get($pixel, sm) + 5px;
                }
                div + div > p {
                  @include heading-text-sm;
                  color: var(--black-text-color);
                  @include font-normal;
                  @include breakpoint("max-md") {
                    display: none;
                  }
                }
                div + div > h6 {
                  @include heading-text-xs;
                  color: var(--primary-color);
                  margin: 0;
                  @include breakpoint("max-md") {
                    display: none;
                  }
                }
                div + div > span {
                  @include heading-text-xs;
                  display: block;
                  @include font-normal;
                  color: var(--dark-gray);
                  @include breakpoint("max-md") {
                    display: none;
                  }
                }
                div:first-child {
                  position: relative;
                  @include breakpoint("max-md") {
                    // margin-right: map-get($pixel, md) * 2 + 7px;
                  }
                  div {
                    width: 16px;
                    height: 16px;
                    background-color: var(--bg-danger);
                    border-radius: 50%;
                    @include flex-center;
                    justify-content: map-get($layout, center);
                    @include pointer;
                    position: absolute;
                    top: -5px;
                    right: -8px;
                    > span {
                      @include heading-text-sm;
                      font-size: map-get($pixel, sm) * 2 + 2px;
                      color: var(--text-white);
                    }
                  }
                }
              }
            }

            .multiple-view-checkout-cart {
              // padding: 30px 21px;
              padding: 12px;

              @include flex-between;
              .yourcart-button {
                button {
                  padding: 10px 24px;
                  border-radius: 5px;
                  @include heading-6;
                  color: var(--bg-white);
                  height: 40px;
                  line-height: 15px;
                  border: none;
                  background-color: var(--secondary-color);
                }
              }
              .checkout-button {
                button {
                  padding: 10px 24px;
                  border-radius: 5px;
                  @include heading-6;
                  color: var(--bg-white);
                  height: 40px;
                  line-height: 15px;
                  border: none;
                  background-color: var(--primary-color);
                }
              }
            }
            .cart-grid:last-child {
              border-bottom: 1px solid var(--gray-border-color);
            }
            .cart-grid {
              padding: 0 0 map-get($pixel, md) * 2 + map-get($pixel, sm) 0;
              @include grid-layout;
              grid-template-columns: 80px minmax(0, 1fr) 10px;
              gap: 0 13px;
              .cart-grid-items {
                img {
                  @include pointer;
                }
                h3 {
                  @include heading-text-sm-normal;
                  margin: 0 0 map-get($pixel, md) 0;
                  color: var(--primary-color);
                  cursor: pointer;
                }
                p {
                  @include heading-text-sm-normal;
                  color: var(--black-1);
                  margin: 0;
                }
                .product-image-show {
                  width: 100%;
                  border-radius: 6px;
                  height: 80px;
                  @include flex-center;
                  justify-content: map-get($layout, center);
                  background-color: var(--bg-light-300);
                  img {
                    min-width: 52px;
                    max-height: 66px;
                    height: 66px;
                    width: 52px;
                  }
                }
              }
            }
            .html-parser {
              white-space: nowrap;
              margin-bottom: 0px;
            }
          }
        }
      }
      .info-relative:hover .shopping-cart-dropdown {
        max-height: calc(100vh - 100px) !important;
        @include ease-in-out;
      }
      .shopping-cart-dropdown-hide {
        display: none;
      }
      .info-wrapper {
        @include flex-center;
        @include pointer;
        padding-right: 20px;
        @include breakpoint("max-sm") {
        padding-right: 8px;
        }
        div:first-child {
          @include flex-center;
        }
        i {
          font-size: 24px;
          color: var(--black-icon);
          @include pointer;
          @include breakpoint("max-sm") {
            font-size: 20px;
            }
        }
        div + div {
          padding-left: map-get($pixel, sm) + 5px;
        }
        div + div > p {
          @include heading-text-sm;
          color: var(--black-text-color);
          @include font-normal;
          @include breakpoint("max-md") {
            display: none;
          }
        }
        div + div > h6 {
          @include heading-text-xs;
          color: var(--primary-color);
          margin: 0;
          @include breakpoint("max-md") {
            display: none;
          }
        }
        div + div > span {
          @include heading-text-xs;
          display: block;
          @include font-normal;
          color: var(--dark-gray);
          @include breakpoint("max-md") {
            display: none;
          }
        }
        div:first-child {
          position: relative;
          @include breakpoint("max-md") {
            // margin-right: map-get($pixel, md) * 2 + 7px;
          }
          div {
            width: 16px;
            height: 16px;
            background-color: var(--bg-danger);
            border-radius: 50%;
            @include flex-center;
            justify-content: map-get($layout, center);
            @include pointer;
            position: absolute;
            top: -5px;
            right: -8px;
            > span {
              @include heading-text-sm;
              font-size: map-get($pixel, sm) * 2 + 2px;
              color: var(--text-white);
            }
          }
        }
      }
      .main-header-middle-grid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 260px;
        gap: 0 map-get($pixel, md) * 2 - 2px;
        justify-content: space-between;
        @media (max-width: 1600px) {
          grid-template-columns: minmax(0, 1fr) 240px;
        }
        @include breakpoint("max-md") {
          display: none;
        }
        .main-header-middle-grid-items {
          .search-dropdown {
            width: 100%;
            z-index: 9999999;
            position: absolute;
          }
          .store-location {
            width: 100%;
            height: 46px;
            border: 1px solid var(--light-border-color);
            @include normal-radius;
            @include pointer;
            @include flex-center;
            padding: 0 map-get($pixel, sm) * 3;

            div > span {
              padding-left: map-get($pixel, sm) + 2px;
              @include heading-text-sm-normal;
              color: var(--gray-4);
            }
          }
          .form-group {
            border: 1px solid var(--light-border-color);
            height: 46px;
            @include flex-center;
            width: map-get($pixel, w-full);
            border-radius: calc(5px - 1px);
            .search-products {
              width: map-get($pixel, w-full);
              .relative-div {
                position: relative;
                .icon-wrapper {
                  position: absolute;
                  top: 50%;
                  right: 10px + 7px;
                  transform: translateY(-50%);
                }
                > input {
                  width: 100%;
                  height: 46px;
                  padding: 0 10px;
                  background-color: transparent;
                  transition: 0.2s ease-in-out;
                  border: 1px solid transparent;
                  @include heading-text-sm-normal;
                  border-radius: 4px;
                  color: var(--text-current);
                  &:focus {
                    border: 1px solid var(--primary-color);
                  }
                }
                ::placeholder {
                  @include heading-text-sm-normal;
                  color: var(--text-current);
                  @include font-normal;
                }
              }
            }
            .categories-menu {
              position: absolute;
              width: 100%;
              z-index: 9999;
              top: 45px;
              left: 0;
            }
            .categories {
              @include flex-center;
              padding-right: map-get($pixel, sm) + 1px;
              border-right: 1px solid var(--light-border-color);
              position: map-get($layout, relative);
              padding-left: map-get($pixel, md) * 2 - 1px;
              height: 100%;
              min-width: 200px;
              justify-content: space-between;
              > span {
                @include heading-text-sm-normal;
                @include pointer;
                white-space: nowrap;
                @include font-normal;
                color: var(--gray-4);
                display: block;
                padding-right: map-get($pixel, sm) * 2 + 1px;
                min-width: 80px;
              }
              > img {
                @include pointer;
              }
            }
          }
        }
      }
    }
    
  }
}
.modal{
&.store-selector-modal{
  width: 500px;
}
}
.store-selector-modal {
  .store-selector-modal-header {
    background-color: var(--primary-light-color);
    border-radius: 8px;
    height: 130px;
    @include breakpoint("max-sm") {
      height: 100px;
    }
    .logo-center-align {
      @include flex-center;
      height: 100%;
      justify-content: map-get($layout, center);
      img {

      width: auto;
      height: auto;
      max-width: 100%;
      
      max-height: 66%;

        @include breakpoint("max-sm") {
          max-width: 150px;
        }
      }
    }
  }

  .store-selector-modal-body {
    padding: map-get($pixel, md) * 2 map-get($pixel, md) * 8 - 4px;
    @include breakpoint("max-sm") {
      padding: map-get($pixel, md) * 2;
    }

    .store-modal-select {
      position: relative;
      width: 100%;
      user-select: none;
      border: 1px solid var(--border-color-2);
      padding-left: 36px;
      height: 40px;
      border-radius: 4px;
      @include flex-center;
      span {
        @include text-sm;
        color: var(--gray-4);
      }
      .dropdown {
        left: 0;
        top: 38px;
        overflow: auto;
      //  min-height: 200px;
        height:250px;
      }

      .store-modal-location-icon {
        left: map-get($pixel, sm) * 3 - 1px;
      }
      .store-modal-down-icon {
        right: map-get($pixel, sm) * 3 - 1px;
      }
      .store-modal-down-icon,
      .store-modal-location-icon {
        position: absolute;
        top: 50%;
        @include flex-center;
        transform: translateY(-50%);
        > img {
          @include pointer;
        }
      }
    }
    .dropdown {
      width: 100%;
      z-index: 9999999;
      position: absolute;
    }
    .option-wrapper {
      @include flex-center;
      justify-content: map-get($layout, center);
      padding: 0 0 map-get($pixel, md) * 3 0;

      div:first-child {
        padding-right: map-get($pixel, md) + 1px;
      }
      div:last-child {
        padding-left: map-get($pixel, md) + 1px;
      }
      div {
        @include flex-center;
      }
      div > input[type="radio"] {
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
      }
      div > label {
        @include text-md;
        display: block;
        padding-left: map-get($pixel, md) * 2 + 1px;
        color: var(--gray-3);
      }
    }
    .input-label{
      margin-bottom: 12px;
    }
  }
}

.continue-shopping-btn {
  padding-top: 15px;
  button {
    @include heading-text-sm;
    color: var(--bg-white);
    background-color: var(--primary-color);
    padding: map-get($pixel, md) * 2 map-get($pixel, sm) * 3;
    border-radius: 5px;
    border: none;
    height: 52px;
    @include flex-center;
    justify-content: map-get($layout, center);
    width: 200px;
    margin-left: auto;
    span {
      padding-right: map-get($pixel, sm) * 2;
    }
  }
}
.html-parser {
  text-align: center;
  margin-bottom: 12px;

  .success {
    color: var(--primary-color);
  }
  .delivery-text {
    color: var(--gray-7);
  }
  .danger {
    color: var(--bg-danger);
  }
}
.disable-btn {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.wish-list {
  // padding-left: 150px;
  @include breakpoint("max-sm") {
    padding-right: 20px;
  }
}
