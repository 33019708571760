@import "styles/mixins/index";

.checkout-information-wrapper {
  .checkout-information {
    border: 1px solid var(--border-color-8);
    border-radius: calc(10px - 2px);
    margin: 0 0 map-get($pixel, md) * 3 0;

    .place-order-text {
      padding: 17px 25px 62px 25px;

      @include breakpoint("max-sm") {
        padding: 15px 15px 30px 15px;
      }
      .available-coupon-message {
        @include text;
        color: var(--bg-danger);
        margin: 0 0 18px 0;
      }

      h2 {
        @include text;
        color: var(--bg-danger);
        margin: 0 0 18px 0;
      }

      p {
        @include text;
        margin: 0 0 30px 0;
        color: var(--gray-3);

        span {
          color: var(--primary-color);
        }

        a {
          @include font-semi-bold;
        }
      }
      .disable {
        opacity: 0.6;
        pointer-events: none;
        cursor: default;
      }

      button {
        width: 100%;
        background-color: var(--primary-color);
        @include heading-text-md-medium;
        border-radius: calc(5px - 0px);
        border: none;
        color: var(--bg-white);
        height: 62px;
        padding: 10px 32px;
        margin-top: 10px;

        @include breakpoint("max-sm") {
          height: 50px;
        }
      }

      .ebt-paid-list {
        padding: 25px 30px;
        @include breakpoint("max-sm") {
          padding: 15px;
        }
        .ebt-paid-list-item {
          border-bottom: 1px solid var(--border-color-8);
          background-color: var(--bg-primary-light);
          padding: 26px 35px;
          div {
            margin-bottom: 4px;
          }
          .card-name{
            display: flex;
            justify-content: space-between;
          }
          .cancel-payment{
            display: flex;
            justify-content: flex-end;
            span{
              color: var(--bg-danger);
              cursor: pointer;
            }
          }
        }

        .ebt-paid-list-item:last-child {
          border-bottom: none;
        }
        .balance-to-pay{
          margin-top: 8px;
          text-align: center;
          color: var(--bg-danger);
        }
      }
    }

    .note-text-style {
      padding: 0 30px 25px 60px;
      border-bottom: 1px solid var(--border-color-8);

      @include breakpoint("max-sm") {
        padding: 0 15px 15px 30px;
      }

      p {
        @include text;
        color: var(--gray-3);
        margin: 0 0 20px 0;
      }

      p:last-child {
        margin: 0;
      }

      .curbside-pickup-checkbox {
        label {
          display: flex;
        }
      }
      .curbside-pickup-comment {
        padding: 25px 0 0 0;

        .form-control {
          label {
            font-size: 21px;
            @include font-medium;

            @include breakpoint("max-sm") {
              @include heading-text-sm;
            }
          }
        }
      }
      .html-parser {
        text-align: left;
      }
    }
    .age-restricted {
      color: var(--bg-danger);
    }

    .enter-mobile-number {
      padding: 25px 30px;

      @include breakpoint("max-sm") {
        padding: map-get($pixel, sm) * 3;
      }

      .enter-mobile-number-box {
        background-color: var(--bg-light-600);
        padding: 25px 30px;

        @include breakpoint("max-sm") {
          padding: map-get($pixel, sm) * 3;
        }
        .contact-helper-text {
          color: var(--bg-danger);
          font-size: 12px;
          width: 100%;
          display: block;
          padding-left: 145px;
        }

        .number-grid {
          @include grid-layout;
          grid-template-columns: 110px 18px minmax(0, 1fr);
          gap: 0 10px;
          align-items: center;
          @include breakpoint("max-sm") {
            gap: 0;
          }
          .number-grid-items {
            .call-me-relative-div {
              position: relative;

              .down-icon-align {
                position: absolute;
                top: 50%;
                @include pointer;
                transform: translateY(-50%);
                @include flex-center;
                right: 15px;

                i {
                  font-size: 12px;
                  color: var(--primary-color);
                }
              }
            }

            .at-text {
              span {
                @include text;
                color: var(--gray-3);
              }
            }

            .form-control {
              position: relative;

              .show-items {
                position: relative;
                @include breakpoint("max-sm") {
                  width: 100%;
                }
                @include breakpoint("min-md") {
                  width: unset;
                }
                button {
                  font-size: 15px;
                  line-height: 19px;
                  font-family: var(--heading-font-family),
                    var(--default-font-family);
                  font-weight: 500;
                  padding: 12px 17px;
                  border: 1px solid var(--light-border-color);
                  color: var(--black-5-light);
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  background-color: transparent;
                  font-weight: 400;
                  width: 116px;
                  min-width: 142px;
                  position: relative;
                  transition: 0.2s ease-in-out;
                  @include breakpoint("max-sm") {
                    width: 100%;
                    min-width: 127px;
                  }
                  &:focus {
                    border: 1px solid var(--primary-color);
                  }

                  span {
                    padding: 0 map-get($pixel, sm) + 1px;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  }
                }
                .submenu-list {
                  .disable-lable {
                    font-size: 14px;
                    line-height: 16px;
                    font-family: var(--heading-font-family),
                      var(--default-font-family);
                    font-weight: 400;
                    color: var(--black-3);
                    padding: 11px 15px;
                    opacity: 0.6;
                  }
                  .submenu-item {
                    padding: 11px 30px;
                  }
                }

                .dropdown-show {
                  max-height: 300px;
                  z-index: 999999;
                  overflow-y: auto;
                  border: 1px solid var(--light-border-color);
                  .list-menu {
                    border: none;
                  }
                }
                .dropdown-hidden {
                  z-index: 999999;
                }
              }

              input {
                height: 36px;
                @include text;
              }

              ::placeholder {
                color: #333333 !important;
                @include text;
              }
            }
          }
        }

        h3 {
          @include text-md-semi-bold;
          margin: 0 0 map-get($pixel, sm) 0;
          color: var(--black-5);
        }

        p {
          @include text-sm-normal;
          margin: 0 0 map-get($pixel, md) 0;
          color: var(--black-5);
        }
      }
    }

    .box-heading {
      background-color: var(--checkout-page-amount-info-bg-color);
      padding: map-get($pixel, md) + 3px map-get($pixel, md) * 2 + 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        text-decoration: underline;
      }

      h1 {
        @include text-md-semi-bold;
        color: var(--checkout-page-amount-info-font-color);
      }
    }
    .discount-coupon-aligment {
      padding: map-get($pixel, md) * 2 map-get($pixel, md) * 2 + 5px;
      border-bottom: 1px solid var(--border-color-8);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      @include breakpoint("max-sm") {
        padding: map-get($pixel, sm) * 3;
      }
      .all-text-alignment {
        border-bottom: none;
        padding: 0;
      }
      .coupon-dropdown-wrapper {
        padding-top: 10px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @include breakpoint("max-sm") {
          display: flex;
          align-items: flex-start;
          padding-left: 20px;
          flex-direction: column;
        }
        .coupon-apply-btn {
          padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
          margin-left: 15px;
          background-color: var(--secondary-color);
          color: var(--text-white);
          @include text-xs-semi-bold;
          border: none;
          @include normal-radius;
          @include flex-center;
          @include pointer;
          @include ease-in-out;

          span {
            padding-left: 4px;
          }
          svg {
            stroke: var(--primary-color);
            @include ease-in-out;
            g {
              path {
                fill: var(--primary-color);
              }
            }
          }
          &:disabled {
            cursor: default;
          }
        }
      }
      .coupon-dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          color: var(--primary-color);
          padding-bottom: 10px;
        }
      }
      .coupon-dropdown-list {
        .coupon-dropdown-item {
          user-select: none;
          display: flex;
          align-items: center;
          position: relative;
          padding: 0 0 12px 0;
          margin-left: 15px;
          label {
            display: flex;
            align-items: center;
          }
        }
        .coupon-dropdown-helper-text {
          font-size: 14px;
          margin-left: 15px;
          user-select: none;
          padding-bottom: 10px;
        }
      }
      .coupon-dropdown-show {
        max-height: 100vh;
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }
      .coupon-dropdown-hidden {
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }

      div {
        p {
          @include heading-4;
          line-height: 22px;
          @include flex-center;
          color: #3d3d3d;

          @include breakpoint("max-sm") {
            @include heading-text-sm;
          }
        }
        h2 {
          font-size: 20px;
          color: var(--primary-color);
        }
      }
      &.hidden {
        display: none;
      }
    }
    .all-text-alignment {
      padding: 7px 25px;
      border-bottom: 1px solid var(--border-color-8);
      // @include flex-between;
      display: flex;
      justify-content: space-between;

      @include breakpoint("max-sm") {
        padding: 7px 15px;
      }

      div {
        p {
          @include heading-4;
          line-height: 22px;
          @include flex-center;
          color: #3d3d3d;

          @include breakpoint("max-sm") {
            @include heading-text-sm;
          }

          span {
            color: var(--secondary-color);
            padding-left: 5px;
          }
        }

        .ornage-text-color {
          color: var(--secondary-color);
        }

        h2 {
          font-size: 20px;
          color: var(--primary-color);
        }

        span {
          @include heading-4;
          color: var(--black-3);
          display: block;
        }
      }
    }
  }

  .payment-modal-body {
    padding: 0;
    height: 294px;
    .form-wrapper {
      padding-top: 64px;
      .help-text {
        padding: 64px;
        margin-bottom: 12px;
        color: var(--primary-color);
        font-weight: bold;
        font-size: 18px;

        @include breakpoint("max-sm") {
          padding: 16px;
          white-space: nowrap;
          font-size: 16px;
        }
      }
    }

    .cc-payment-form {
      background-color: #fff;
      margin: 0;
      padding-top: 20px;
      input {
        font-size: 16px;
        line-height: 28px;
        font-family: var(--heading-font-family), var(--default-font-family);
        font-weight: 400;
        font-weight: 400;
        &::placeholder {
          opacity: 0.6;
        }
      }
      .cqQGRX {
        background-color: #fff;
        color: var(--gray-4);

        border: 1px solid var(--light-border-color);
      }
      .fSWevF {
        background-color: #fff;
        color: var(--gray-4);

        border: 1px solid var(--primary-color);
      }
      .jPkAPB {
        color: var(--bg-danger);
      }

      button {
        width: 100%;
        background-color: var(--primary-color);
        font-size: 18px;
        line-height: 24px;
        font-family: var(--heading-font-family), var(--default-font-family);
        font-weight: 500;
        border-radius: calc(5px - 0px);
        border: none;
        color: var(--bg-white);
        height: 54px;
        padding: 10px 32px;
        margin-top: 10px;
        box-shadow: none;
      }
    }
    .StripeElement {
      display: block;
      margin: 0px 64px 0px;
      // max-width: 500px;
      padding: 10px 14px;
      font-size: 1em;
      font-family: "Source Code Pro", monospace;
      box-shadow: rgb(50 50 93 / 15%) 0px 1px 3px, rgb(0 0 0 / 2%) 0px 1px 0px;
      border: 0;
      outline: 0;
      border-radius: 4px;
      background: white;

      @include breakpoint("max-sm") {
        margin: 0px 12px 0px;
      }
    }

    .stripe-button-wrapper {
      display: flex;
      justify-content: center;
      margin: 50px 0px;
      .stripe-button {
        background-color: var(--primary-color);
        font-size: 18px;
        line-height: 24px;
        font-family: var(--heading-font-family), var(--default-font-family);
        font-weight: 500;
        border-radius: calc(5px - 0px);
        border: none;
        color: var(--bg-white);
        padding: 10px 32px;
        box-shadow: none;
      }
    }
  }

  .payment-error-message {
    @include flex-center;
    justify-content: center;
    height: 344px;

    h2 {
      margin-bottom: 20px;
    }
    button {
      font-size: 15px;
      line-height: 19px;
      font-family: var(--heading-font-family), var(--default-font-family);
      font-weight: 500;
      padding: 12px 17px;
      border: 1px solid var(--light-border-color);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--primary-light-color);
      color: var(--primary-color);
      font-weight: 400;
      width: 116px;
      position: relative;
      transition: 0.2s ease-in-out;
      @include breakpoint("max-sm") {
        width: 100%;
      }
      &:hover:not([disabled]) {
        background-color: var(--primary-color);
        color: var(--bg-white);
      }
      &:focus {
        border: 1px solid var(--primary-color);
      }
    }

    .btn-center {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .stripe-payment-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 12px;
  }

  .processing-card {
    display: flex;
    flex-direction: column;
    .client-logo {
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }
    .payment-process-message {
      @include flex-center;
      justify-content: center;
      flex-direction: column;
      h2 {
        margin: 40px 0px;
      }
      p {
        color: var(--primary-color);
      }
    }
  }
}
