@import "styles/mixins/index";

.change-pin-section {
    @include breakpoint("max-sm") {
        padding: 27px 0;
    }

    h1 {
        @include heading-2;
        color: var(--invert-secondary-color);
        margin: 0 0 26px 0;

        @include breakpoint("max-sm") {
            @include heading-3xl;
        }
    }

    .change-pin-box {
        padding: 44px 47px;
        border: 1px solid var(--border-color-8);
        border-radius: 8px;

        @include breakpoint("max-sm") {
            padding: 25px;
        }

        .form-control {
            margin: 0 0 15px 0;

            span {
                color: var(--bg-danger);
                font-size: 12px;
            }
        }

        .button-right-side {
            @include flex-center;
            justify-content: flex-end;

            div {
                button {
                    min-width: 120px;
                    height: 44px;
                    border-radius: 4px;
                    @include heading-4;
                    border: none;
                }
            }

            div:first-child {
                margin-right: 15px;

                button {
                    background-color: var(--bg-light-200);
                    color: #989898;
                }
            }

            div:last-child {
                button {
                    background-color: var(--primary-color);
                    color: var(--bg-white);
                }
            }
        }

        .feedback-success {
            display: block;
            margin-bottom: 10px;
            color: #13c487;
        }   
        .feedback-error {
            display: block;
            margin-bottom: 10px;
            color: #fd1f00;
        }
    }
}